import React from 'react';

function CommandLine() {
  return (
    <>

        <div className='terminal' id='terminal'>
          <div className='terminal-header'>
            <span className='close' id='close-terminal-button'>
              &#10005;
            </span>
          </div>
          <div className='terminal-content'>
            <div className='line'>
              <span className='prompt'>user@fusepress:~$</span>
              <span className='command text-white'> echo "Hello, Terminal!"</span>
              <span className='cursor'></span>
            </div>
          </div>
        </div>

    </>
  );
}

export default CommandLine;
