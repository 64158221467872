import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticationMutation, useCheckUserMutation } from '../features/api';
import { toast } from 'react-toastify';

const AuthOtp: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(300);
  const [otp, setOtp] = useState('');
  const [Auth] = useAuthenticationMutation();
  const [checkUser] = useCheckUserMutation();

  const navigate = useNavigate();

  useEffect(() => {
    checkUser({ uniqueCode: localStorage.getItem('uniqueCode') }).then((r: any) => {
      if (r.data.status !== 'true') {
        // console.log(r.data.status)
      } else {
        // console.log(r.data.status)
      }
    });
  }, [checkUser]);

  useEffect(() => {
    if (timeLeft === 0) {
      window.location.href = process.env.PUBLIC_URL + '/login/';
    }
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  if (localStorage.getItem('token')) {
    navigate('/');
  }

  function handleSubmit() {
    Auth({ otp: otp })
      .then((r: any) => {
        if (!r.data.error) {
          toast.success('Success');
          localStorage.setItem('token', r?.data?.access_token);
          localStorage.setItem('userDetails', JSON.stringify(r?.data?.user));
          setTimeout(() => {
            navigate('/');
          }, 1000);
        } else {
          toast.error(r.data.message);
        }
      })
      .catch(e => {
        toast.error(`${e}`);
      });
  }

  return (
    <main className='main'>
      <div className='container-fluid'>
        <div className='d-flex align-items-center justify-content-center auth-wrap'>
          <div className='auth'>
            <h6 className='text-white'>Two-Factor Authentication</h6>
            <p className='otp-text'>OTP sent please check your mail.</p>
            <form>
              <div className='mb-3'>
                <input
                  placeholder='Two-Factor Code'
                  type='text'
                  className='form-control form-square'
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  onChange={e => setOtp(e.target.value)}
                />
              </div>
              <button type='button' className='orange_btn w-100' onClick={handleSubmit}>
                Continue
              </button>
            </form>
            <div className='otp-text mt-3'>
              <a
                href={`mailto:contact@thethirdwave.co`}
                className='text-decoration-none primary-color'
                target='_blank'
                rel='noreferrer'
              >
                Problems receiving your OTP?
              </a>
            </div>
            <div className='otp-text'>
              Remaining time is <span className='primary-color'>{timeLeft}</span> seconds
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuthOtp;
