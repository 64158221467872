/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import favorite_unselect from '../img/favorite.svg';
import favorite_select from '../img/favorite_select.svg';
import { Rating } from 'react-simple-star-rating';
import { useGetDataByPluginQuery } from '../features/api';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from './Loader';
import { Steps } from 'intro.js-react';
import { faHeart, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import cover_image from '../img/fuse-cover.png';
import thumbnail_image from '../img/fuse-thumbnail.png';
import backarrow from '../img/acc-arw.svg';
import NotFound from './NotFound';
import { toast } from 'react-toastify';
import { useDeleteQueryString, useManageQueryString, useUpdateQueryString } from '../utils/queryStringFunc';
import { useAppSelector } from '../features/hooks';
import { updateTrigger } from '../features/introArray';
import { useDispatch } from 'react-redux';
import { setWishlistNumber } from '../features/notifyNumbers';
import ErrorPage from './ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';

const PluginDetails: React.FC = () => {
  const storePlugins =
    localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];
  const [selectPluginData, setSelectPluginData] = useState<Array<any>>(storePlugins);
  const [plugDetails, setPlugDetails] = useState<any>({});
  const [versionList, setVersionList] = useState<Array<any>>([]);
  const [specificVerSelect, setSpecificVerSelect] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const favorite = false ? favorite_select : favorite_unselect;
  const { slug } = useParams();
  const { t } = useTranslation();

  const { data: pluginDetails, error, isLoading, isError } = useGetDataByPluginQuery({ plugin_id: slug });
  const [coverImg, setCoverImg] = useState<any>(`https://ps.w.org/${slug}/assets/banner-1544x500.png`);
  const [thumbImg, setThumbImg] = useState<any>(`https://ps.w.org/${slug}/assets/icon-128x128.png`);
  const [listSS, setListSS] = useState<Array<any>>([]);
  const [isPluginInWishlist, setIsPluginInWishlist] = useState(false);
  const [color, setColor] = useState('');
  const navigate = useNavigate();
  const [stepsEnabled, setStepsEnabled] = useState<any>(null);
  const queryString = new URLSearchParams(window.location.search);
  const location = useLocation();
  const deleteQueryString = useDeleteQueryString();
  const updateQueryString = useUpdateQueryString();
  const manageQueryString = useManageQueryString();
  const introArray = useAppSelector(state => state.introArray.arrayData);
  const [wishlist1, setWishlist] = useState([]);
  const [disablePrivatepack, setDisablePrivatepack] = useState<boolean>(false);
  // console.log("introArray",introArray)
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("introArray",introArray)
    if (introArray.length > 0) {
      const getspecificdata = introArray.find((ik: any) => ik.page === 'plugin-details');
      if (getspecificdata !== undefined) {
        if (getspecificdata.trigger === true) {
          setStepsEnabled(true);
        }
      }
    }
  }, [introArray]);

  useEffect(() => {
    if (pluginDetails) {
      if (!Object.prototype.hasOwnProperty.call(pluginDetails, 'error')) {
        if (pluginDetails.dataType !== 'theme') {
          if (pluginDetails.plugin.plugin_path !== null && pluginDetails.plugin.plugin_path.length > 0) {
            setDisablePrivatepack(true);
          }
          if (pluginDetails.pluginVersion.length > 0) {
            if (
              pluginDetails.pluginVersion.some((obj: any) => {
                return obj.fusepress_path !== null;
              })
            ) {
              setDisablePrivatepack(true);
            }
          }
        }
      }
    }
  }, [pluginDetails]);

  // useEffect(() => {

  //   console.log('hello',location.search)
  //   if(location.search.length > 0){
  //     if(queryString.get("intro")){
  //       setStepsEnabled(true)
  //     }
  //   }

  // },[location.search])

  const pathname = window.location.pathname;
  const getPlugins =
    localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : null;
  let initialSteps = 0;
  let PluginDetailSteps = [
    {
      title: t('intro.plugin-details.first.title'),
      // element: ".store-main-first",
      position: 'right',
      intro: t('intro.plugin-details.first.desc'),
    },
    {
      title: t('intro.plugin-details.two.title'),
      element: '.plugin-details-first',
      position: 'left',
      intro: t('intro.plugin-details.two.desc'),
    },
    {
      title: t('intro.plugin-details.three.title'),
      element: '.plugin-details-second',
      position: 'left',
      intro: t('intro.plugin-details.three.desc'),
    },
    {
      title: t('intro.plugin-details.four.title'),
      element: '.plugin-details-third',
      position: 'left',
      intro: t('intro.plugin-details.four.desc'),
    },
  ];

  // console.log("isError",isError,pluginDetails,)

  useEffect(() => {
    const fetchAndParseHTML = async () => {
      // console.log("helllistSS")
      try {
        const response = await fetch(
          `${process.env.REACT_APP_AUTH_NODE_URL}/api/fetchHtml/fetch-html?url=https://ps.w.org/${slug}/assets/`
        );
        const text = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        const links = Array.from(doc.querySelectorAll('a'));
        // console.log("screenshotImages",links)

        const screenshotImages = links
          .map(link => link.getAttribute('href'))
          .filter(href => href && href.toLowerCase().includes('screenshot'))
          .map(href => `https://ps.w.org/${slug}/assets/${href}`);
        // console.log("screenshotImages",screenshotImages)
        setListSS(screenshotImages);
        if (typeof pluginDetails !== 'undefined') {
          if (Object.prototype.hasOwnProperty.call(pluginDetails.plugin, 'screenshots')) {
            const aar = JSON.parse(pluginDetails.plugin.screenshots);
            // console.log("aar",aar)
            const ar2 = aar.map((ik: any) => {
              const ar1 = ik.split('//')[1];
              // console.log("ar1",ik.split('//'))
              return `https://${ar1}`;
            });
            // console.log("listSSlistSS",listSS,ar2)
            // setListSS([...listSS,...ar2])
            setListSS(ar2);
          }
        }
        console.log(screenshotImages);
        // setImages(screenshotImages);
      } catch (error) {
        console.error('Error fetching and parsing HTML:', error);
      }
    };

    fetchAndParseHTML();
  }, [pluginDetails, slug]);
  // console.log('listSS', listSS)

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'plugin-details-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'plugin-details-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'plugin-details-screen', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        // manageQueryString(homeScreen.status)
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        // manageQueryString(array[index].status)
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'plugin-details-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'plugin-details-screen';
      });
      // if(index < 0){
      //    const homeScreen = {screen:"home-screen", status:'false'}
      //     array.push(homeScreen)
      //     setStepsEnabled(
      //       homeScreen.status === 'true' ? true : false
      //     );
      //     localStorage.setItem("introjs",JSON.stringify(array!))
      // }
      if (index >= 0) {
        array[index].status = 'false';
        // manageQueryString(array[index].status)
        dispatch(updateTrigger({ page: 'plugins-details', trigger: false }));
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };
  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'plugin-details-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };

  useEffect(() => {
    dispatch(setWishlistNumber(wishlist1.length));
  }, [wishlist1]);

  useEffect(() => {
    if (typeof pluginDetails !== 'undefined') {
      if (!Object.prototype.hasOwnProperty.call(pluginDetails, 'error')) {
        setPlugDetails(pluginDetails);
        const sortedVersion = [...pluginDetails.pluginVersion].sort((objA: any, objB: any) => {
          const versionA = objA.version.split('.').map(Number);
          const versionB = objB.version.split('.').map(Number);

          const maxLength = Math.max(versionA.length, versionB.length);

          for (let i = 0; i < maxLength; i++) {
            const componentA = versionA[i] || 0;
            const componentB = versionB[i] || 0;

            if (componentA > componentB) {
              return -1; // Return -1 to sort in descending order
            } else if (componentA < componentB) {
              return 1; // Return 1 to sort in descending order
            }
          }

          return 0; // Versions are equal
        });
        setVersionList(sortedVersion);
        if (sortedVersion.length > 0) {
          setSpecificVerSelect(sortedVersion[0]);
        }

        // console.log('sortedVersion', sortedVersion);
      }
    }
  }, [slug, pluginDetails]);

  useEffect(() => {
    // console.log("pluginDetails",pluginDetails)
    if (typeof pluginDetails !== 'undefined') {
      const wistlistName = 'wishlist';
      const wishlistString = localStorage.getItem(wistlistName);
      const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
      setWishlist(wishlist);
      if (Array.isArray(wishlist)) {
        const isAlreadyInWishlist = wishlist.some(
          (plugin: any) => plugin.id === pluginDetails?.plugin?.id && plugin.dataType === pluginDetails.dataType
        );
        if (isAlreadyInWishlist) {
          setIsPluginInWishlist(true);
          setColor('#F08921');
        }
      }
    }
  }, [pluginDetails?.plugin?.id]);

  const specificVersion = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const findVar = pluginDetails.pluginVersion.find((i: any) => {
      return i.version === event.target.value;
    });
    if (findVar !== undefined) {
      setSpecificVerSelect(findVar);
    }
  };

  const ratingCount = (rate: number): number => {
    if (typeof pluginDetails !== 'undefined') {
      if (Object.prototype.hasOwnProperty.call(pluginDetails.plugin, 'ratings')) {
        const totalRate =
          pluginDetails.plugin.ratings[1] +
          pluginDetails.plugin.ratings[2] +
          pluginDetails.plugin.ratings[3] +
          pluginDetails.plugin.ratings[4] +
          pluginDetails.plugin.ratings[5];
        if (pluginDetails.plugin.rating > 0) {
          const ab = Math.round((pluginDetails.plugin.ratings[rate] / totalRate) * 100);
          return ab;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectClick = () => {
    // console.log("pluginDetails",pluginDetails,getPlugins)
  };

  const onAddPlugin = (pluginDetails: any, event: any): void => {
    // console.log('plugin', pluginDetails, event, selectPluginData);
    const plugin = pluginDetails.plugin;
    const newObj = { ...plugin, versions: pluginDetails.pluginVersion };
    const a = selectPluginData;
    const filterdata = selectPluginData.find((i: any) => {
      return i.id === plugin.id;
    });
    if (filterdata !== undefined) {
      const ab = a.filter((i: any) => {
        return i.id !== plugin.id;
      });
      // console.log("ab",ab)
      localStorage.setItem('storequeue', JSON.stringify(ab!));
      setSelectPluginData(ab);
    } else {
      const abc = [...a, newObj];
      localStorage.setItem('storequeue', JSON.stringify(abc!));
      setSelectPluginData(abc);
    }
  };
  // console.log("selectPluginData",selectPluginData)
  const isSelectedActive =
    selectPluginData?.find((ik: any) => {
      const plugin = pluginDetails?.plugin;
      return ik.id === plugin?.id;
    }) !== undefined
      ? true
      : false;

  const openLightbox = (index: any) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const showPrev = () => {
    setCurrentIndex((currentIndex + listSS.length - 1) % listSS.length);
  };

  const showNext = () => {
    setCurrentIndex((currentIndex + 1) % listSS.length);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (typeof pluginDetails !== 'undefined' && Object.prototype.hasOwnProperty.call(pluginDetails, 'error')) {
    return <NotFound />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const adjustIframeWidth = (htmlString: any) => {
    if (htmlString !== null) {
      const regex = /<iframe .*?width="(.*?)"/g;
      const adjustedHtml = htmlString.replace(regex, '<iframe width="540"');
      return { __html: adjustedHtml };
    } else {
      return { __html: '' };
    }
  };

  const routeToTagFilter = (value: string) => {
    const params = createSearchParams({
      type: 'plugins',
      search: '',
      sort: 'popularity',
      perpage: '20',
      ratings: [],
      pg: '1',
      tag: value,
      vendor: '',
      rating: [],
      order: 'asc',
      datatype: 'public',
      project_id: '',
      env_id: '',
      redirect_to: '',
      flag: 'plugins',
      ispremium: 'false',
    });

    navigate({
      pathname: '/store',
      search: `?${params.toString()}`,
    });
    window.location.reload();
  };

  const addToWishlist = () => {
    if (color === 'black') {
      setColor('F08921');
    } else {
      setColor('F08921');
    }
    console.log('pluginDetails', pluginDetails.plugin.id);
    const wistlistName = 'wishlist';
    const wishlistString = localStorage.getItem(wistlistName);
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
    const isAlreadyInWishlist = wishlist.some(
      (plugin: any) => plugin.id === pluginDetails.plugin.id && plugin.dataType === pluginDetails.dataType
    );
    if (!isAlreadyInWishlist) {
      const plugin = pluginDetails.plugin;
      const newObj = { ...plugin, versions: pluginDetails.pluginVersion, dataType: pluginDetails.dataType };
      wishlist.push(newObj);
      setWishlist(wishlist);
      localStorage.setItem(wistlistName, JSON.stringify(wishlist));
      toast.success('Plugin Added to wishlist');
      console.log('Added to wishlist:', pluginDetails);
    } else {
      const filtered = wishlist.filter((ik: any) => {
        return !(ik.id === pluginDetails.plugin.id && ik.dataType === pluginDetails.dataType);
      });
      setColor('');
      setWishlist(filtered);
      localStorage.setItem(wistlistName, JSON.stringify(filtered));
      toast.error('removed from wish list');
    }
  };

  // const goBack = () => {
  //   navigate(-1);
  // };

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <>
        {pathname.includes('/store/') && (
          <Steps
            enabled={stepsEnabled}
            steps={PluginDetailSteps}
            // onExit={() => (stepsEnabled === false ? false : stepsEnabled)}
            onExit={(e: any) => onIntroExit(e)}
            initialStep={initialSteps}
            options={{
              showProgress: true,
              // showStepNumbers: true,
            }}
            onAfterChange={() => {
              const tooltip = document.querySelector('.introjs-tooltip');
              if (tooltip) {
                const footer = document.createElement('div');
                footer.classList.add('customTooltipFooter');
                footer.innerHTML = `
                <span style="font-size:small; color:white; display:flex; justify-content: center;">Check More Info:&nbsp;
                  <a href="${process.env.REACT_APP_WORDPRESS_STAGING_URL}/documentation" style="font-size:small; color:#F28A03;" target="_blank" rel="noopener noreferrer";>
                    Documentation.
                  </a>
                </span>

              `;
                // Append the footer only if it's not already appended
                if (!tooltip.querySelector('.customTooltipFooter')) {
                  tooltip.appendChild(footer);
                }
              }
            }}

            // onBeforeExit={() => {
            //   let data =
            //     stepsEnabled !== null &&
            //     window.confirm("Don't want to show this again then press Ok");
            //   stepsEnabled !== null &&
            //   data &&
            //   localStorage.setItem("IntroToken", "false");
            // }}
          />
        )}
        {typeof pluginDetails !== 'undefined' && Object.prototype.hasOwnProperty.call(plugDetails, 'plugin') && (
          <main className='main'>
            {/* <div style={{marginLeft:'60px'}}>
        <button type='button' className='cblue_btn back_btn' onClick={goBack}>
                            <span className='back_btn_icon' style={{marginRight:'5px'}}>
                              <img className='img-fluid' src={backarrow} alt='back-btn' />
                            </span>
                            <span>Back to Store</span>
                        </button>
        </div> */}
            <div className='container'>
              <div className='product_inner'>
                <div className='product_banner'>
                  <div className='product_like' onClick={addToWishlist}>
                    {/* <img className='favorite_select img-fluid' src={favorite} alt=''></img> */}
                    <FontAwesomeIcon icon={faHeart} style={{ color: color }} size='lg' />
                  </div>
                  <div className='product_img'>
                    <img
                      src={coverImg}
                      onError={() => {
                        setCoverImg(cover_image);
                      }}
                      alt='img'
                    />
                  </div>
                </div>
                <div className='product_title_price'>
                  <div className='product_card'>
                    <img
                      src={thumbImg}
                      onError={() => {
                        setThumbImg(thumbnail_image);
                      }}
                      alt='img'
                      width={'128px'}
                      height={'128px'}
                    />
                  </div>
                  <div className='product_title plugin-details-first'>
                    <h3 className='text-white'>{plugDetails.plugin.name}</h3>
                    <p>
                      {t('plugin-details.By')}{' '}
                      <a href={pluginDetails.plugin.wp_author_profile} target='_blank'>
                        {pluginDetails.plugin.wp_author}
                      </a>{' '}
                    </p>
                  </div>
                  <div className='product_price'>
                    {/* <p className='main_price'>$100</p>
                <p className='final_price'>$79</p> */}
                    <div className='inline-block'>
                      {/* <button type='button' className='cblue_btn' onClick={() => selectClick()}>
                    <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M5.993 0.375C4.83242 0.375 3.6979 0.719152 2.73292 1.36394C1.76793 2.00872 1.01581 2.92518 0.571678 3.99741C0.127542 5.06965 0.0113366 6.24951 0.237755 7.38779C0.464173 8.52607 1.02305 9.57165 1.8437 10.3923C2.66435 11.213 3.70993 11.7718 4.84821 11.9982C5.98649 12.2247 7.16635 12.1085 8.23859 11.6643C9.31083 11.2202 10.2273 10.4681 10.8721 9.50309C11.5169 8.5381 11.861 7.40358 11.861 6.243C11.8611 5.47237 11.7094 4.70926 11.4146 3.99726C11.1197 3.28526 10.6875 2.63832 10.1426 2.0934C9.59768 1.54848 8.95074 1.11626 8.23874 0.821407C7.52675 0.526559 6.76364 0.374869 5.993 0.375ZM8.993 4.621L5.226 8.41C5.13961 8.4999 5.02328 8.55504 4.899 8.565C4.7724 8.55573 4.65423 8.49807 4.569 8.404L2.989 6.824C2.97821 6.81356 2.96962 6.80105 2.96376 6.78722C2.95789 6.77339 2.95487 6.75852 2.95487 6.7435C2.95487 6.72848 2.95789 6.71361 2.96376 6.69978C2.96962 6.68595 2.97821 6.67344 2.989 6.663L3.489 6.163C3.49918 6.15229 3.51144 6.14376 3.52502 6.13793C3.5386 6.1321 3.55322 6.1291 3.568 6.1291C3.58278 6.1291 3.59741 6.1321 3.61099 6.13793C3.62457 6.14376 3.63682 6.15229 3.647 6.163L4.9 7.416L8.342 3.949C8.35225 3.93836 8.36451 3.92988 8.37808 3.92404C8.39164 3.9182 8.40623 3.91513 8.421 3.915C8.43587 3.91443 8.45068 3.91719 8.46434 3.92307C8.47801 3.92896 8.49019 3.93781 8.5 3.949L8.994 4.46C9.00519 4.4701 9.0142 4.48236 9.02049 4.49606C9.02678 4.50975 9.03022 4.52458 9.03059 4.53964C9.03097 4.5547 9.02827 4.56969 9.02267 4.58367C9.01707 4.59766 9.00867 4.61036 8.998 4.621H8.993Z'
                        fill='white'
                      />
                    </svg>
                    {t('plugin-details.buttons.Select')}
                  </button> */}
                      {plugDetails.dataType === 'theme' ? null : disablePrivatepack === true ? null : (
                        <button
                          className='cblue_btn ms-auto'
                          type='button'
                          style={{ zIndex: 9999999999999 }}
                          onClick={e => onAddPlugin(pluginDetails, e)}
                        >
                          {isSelectedActive ? `${t('store-list.buttons.Remove')}` : `${t('store-list.buttons.Select')}`}
                        </button>
                      )}
                      <button
                        type='button'
                        className='roundedButton'
                        style={{ marginLeft: '80px', marginTop: '10px' }}
                        onClick={() => onActivateIntro()}
                      >
                        <FontAwesomeIcon icon={faQuestion} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className='product_dec_data'>
                  <div className='product_dec'>
                    <h4>{t('plugin-details.Description')}</h4>
                    <div
                      className='text-white'
                      dangerouslySetInnerHTML={adjustIframeWidth(plugDetails.plugin?.description)}
                    />
                  </div>
                  <div className='product_ss_data'>
                    <div className='product_ss'>
                      {listSS.length > 0 && (
                        <div className='product_ss_title'>
                          <h4>{t('plugin-details.Screenshots')}</h4>
                          <a style={{ cursor: 'pointer' }} onClick={() => openLightbox(0)}>
                            {t('plugin-details.see-all')}
                            <svg
                              width='18'
                              height='9'
                              viewBox='0 0 18 9'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M17.3505 0.948017C17.2568 1.19076 17.1009 1.40458 16.8985 1.56802C14.4771 3.85468 12.0585 6.14568 9.64247 8.44102C9.53979 8.56545 9.40254 8.65666 9.24804 8.70312C9.09355 8.74957 8.92875 8.74919 8.77447 8.70202C8.63641 8.65241 8.5101 8.57478 8.40347 8.47402C5.91614 6.12068 3.43047 3.76668 0.94647 1.41202C0.817714 1.30347 0.726495 1.15706 0.685808 0.993645C0.645122 0.830228 0.657043 0.658139 0.719874 0.501893C0.782705 0.345647 0.89324 0.213214 1.03573 0.123459C1.17823 0.033704 1.34541 -0.00879469 1.51347 0.00201712C1.71554 0.0232789 1.90343 0.115806 2.04347 0.263017C3.44347 1.59168 4.84347 2.91802 6.24347 4.24202C7.12947 5.07735 8.01347 5.91435 8.89547 6.75302C8.92847 6.78202 8.96047 6.81002 8.99547 6.84702C9.04047 6.81002 9.08147 6.77802 9.11747 6.74102C11.3745 4.60802 13.6365 2.48402 15.8745 0.332017C15.9713 0.207177 16.1015 0.1123 16.25 0.0583548C16.3985 0.00440982 16.5593 -0.00639899 16.7137 0.0271782C16.8681 0.0607553 17.0098 0.137347 17.1225 0.248097C17.2352 0.358847 17.3142 0.499231 17.3505 0.653017V0.948017Z'
                                fill='#F28A03'
                              />
                            </svg>
                          </a>
                        </div>
                      )}

                      <div className='ss_list'>
                        {listSS.map((i, index) => {
                          return (
                            <img
                              className='favorite_select img-fluid'
                              src={i}
                              onClick={() => openLightbox(index)}
                              alt=''
                            ></img>
                          );
                        })}
                      </div>
                      {isOpen && (
                        <div className='lightbox'>
                          <span className='close' onClick={closeLightbox}>
                            &times;
                          </span>
                          <img
                            className='lightbox-content'
                            src={listSS[currentIndex]}
                            alt={`Lightbox ${currentIndex}`}
                          />
                          <span className='prev' onClick={showPrev}>
                            &#10094;
                          </span>
                          <span className='next' onClick={showNext}>
                            &#10095;
                          </span>
                        </div>
                      )}
                    </div>
                    <div className='product_detail plugin-details-second'>
                      <div className='product_detail_inner'>
                        <div className='product_detail_row'>
                          <p className='data_title'>{t('plugin-details.Version')}:</p>
                          <p className='data_result'>
                            {versionList.length > 0 ? (
                              <select className='environment-select form-select' onChange={e => specificVersion(e)}>
                                {versionList.map((i: any) => {
                                  return <option value={i.version}>{i.version}</option>;
                                })}
                              </select>
                            ) : (
                              plugDetails.plugin?.version
                            )}
                          </p>
                        </div>{' '}
                        {plugDetails.plugin?.last_updated !== null && (
                          <div className='product_detail_row'>
                            <p className='data_title'>{t('plugin-details.Last-updated')}:</p>
                            <p className='data_result'>{plugDetails.plugin?.last_updated}</p>
                          </div>
                        )}
                        {Object.prototype.hasOwnProperty.call(plugDetails.plugin, 'downloaded') && (
                          <div className='product_detail_row'>
                            <p className='data_title'>{t('plugin-details.Active-installations')}:</p>
                            <p className='data_result'>{plugDetails.plugin?.downloaded}+</p>
                          </div>
                        )}
                        {Object.keys(specificVerSelect).length > 0 && (
                          <div className='product_detail_row'>
                            <p className='data_title'>{t('plugin-details.WordPress-Version')}:</p>
                            <p className='data_result'>{specificVerSelect?.wp_min} or higher</p>
                          </div>
                        )}
                        {Object.prototype.hasOwnProperty.call(plugDetails.plugin, 'tested') && (
                          <div className='product_detail_row'>
                            <p className='data_title'>{t('plugin-details.Tested-up-to')}:</p>
                            <p className='data_result'>
                              {plugDetails.plugin?.tested !== null ? plugDetails.plugin?.tested : 'Nil'}
                            </p>
                          </div>
                        )}
                        {Object.keys(specificVerSelect).length > 0 && (
                          <div className='product_detail_row'>
                            <p className='data_title'>{t('plugin-details.PHP-Version')}:</p>
                            <p className='data_result'>{specificVerSelect?.php_min} or higher</p>
                          </div>
                        )}
                        {/* <div className='product_detail_row'>
                      <p className='data_title'>{t('plugin-details.Languages')}:</p>
                      <p className='data_result'>{t('plugin-details.See-all-4')}</p>
                    </div> */}
                        {plugDetails?.pluginTag?.length > 0 && (
                          <div className='product_detail_row'>
                            <p className='data_title'>{t('plugin-details.Tags')}:</p>
                            <p className='data_result'>
                              {' '}
                              {plugDetails?.pluginTag?.length > 0
                                ? plugDetails?.pluginTag.map((i: any) => {
                                    return (
                                      <span style={{ cursor: 'pointer' }} onClick={() => routeToTagFilter(i.name)}>
                                        {i.value}
                                      </span>
                                    );
                                  })
                                : null}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='product_rating plugin-details-third'>
                      <div className='product_rating_title'>
                        <h4>{t('plugin-details.Ratings')}</h4>
                        {/* <a href='#'>
                    {t('plugin-details.see-all')}
                      <svg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M17.3505 0.948017C17.2568 1.19076 17.1009 1.40458 16.8985 1.56802C14.4771 3.85468 12.0585 6.14568 9.64247 8.44102C9.53979 8.56545 9.40254 8.65666 9.24804 8.70312C9.09355 8.74957 8.92875 8.74919 8.77447 8.70202C8.63641 8.65241 8.5101 8.57478 8.40347 8.47402C5.91614 6.12068 3.43047 3.76668 0.94647 1.41202C0.817714 1.30347 0.726495 1.15706 0.685808 0.993645C0.645122 0.830228 0.657043 0.658139 0.719874 0.501893C0.782705 0.345647 0.89324 0.213214 1.03573 0.123459C1.17823 0.033704 1.34541 -0.00879469 1.51347 0.00201712C1.71554 0.0232789 1.90343 0.115806 2.04347 0.263017C3.44347 1.59168 4.84347 2.91802 6.24347 4.24202C7.12947 5.07735 8.01347 5.91435 8.89547 6.75302C8.92847 6.78202 8.96047 6.81002 8.99547 6.84702C9.04047 6.81002 9.08147 6.77802 9.11747 6.74102C11.3745 4.60802 13.6365 2.48402 15.8745 0.332017C15.9713 0.207177 16.1015 0.1123 16.25 0.0583548C16.3985 0.00440982 16.5593 -0.00639899 16.7137 0.0271782C16.8681 0.0607553 17.0098 0.137347 17.1225 0.248097C17.2352 0.358847 17.3142 0.499231 17.3505 0.653017V0.948017Z'
                          fill='#F28A03'
                        />
                      </svg>
                    </a> */}
                      </div>
                      <div className='product_reviews'>
                        <div className='reviews_word'>
                          <p>{t('plugin-details.19-reviews')}</p>
                        </div>
                        <div className='reviews_star'>
                          <Rating
                            initialValue={pluginDetails.plugin.rating / 20}
                            allowFraction={true}
                            fillColor='#F28A03'
                            size={20}
                            className='float-start'
                            emptyColor='#888888'
                            allowHover={false}
                            disableFillHover={true}
                            readonly
                          />
                        </div>
                      </div>
                      <div className='product_star_progress'>
                        {Object.prototype.hasOwnProperty.call(pluginDetails.plugin, 'ratings') && (
                          <div className='star_progress_inner'>
                            <div className='star_progress_row'>
                              <div className='star_count'>
                                <p>{t('plugin-details.5-star')}</p>
                              </div>
                              <div className='progress_bar'>
                                <div
                                  className='progress'
                                  role='progressbar'
                                  aria-label='Basic example'
                                  aria-valuenow={ratingCount(5)}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div className='progress-bar' style={{ width: `${ratingCount(5)}%` }}></div>
                                </div>
                              </div>

                              <div className='star_given_count'>
                                <p>{pluginDetails.plugin?.ratings['5']}</p>
                              </div>
                            </div>
                            <div className='star_progress_row'>
                              <div className='star_count'>
                                <p>{t('plugin-details.4-star')}</p>
                              </div>
                              <div className='progress_bar'>
                                <div
                                  className='progress'
                                  role='progressbar'
                                  aria-label='Basic example'
                                  aria-valuenow={ratingCount(4)}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div className='progress-bar' style={{ width: `${ratingCount(4)}%` }}></div>
                                </div>
                              </div>

                              <div className='star_given_count'>
                                <p>{pluginDetails.plugin?.ratings['4']}</p>
                              </div>
                            </div>
                            <div className='star_progress_row'>
                              <div className='star_count'>
                                <p>{t('plugin-details.3-star')}</p>
                              </div>
                              <div className='progress_bar'>
                                <div
                                  className='progress'
                                  role='progressbar'
                                  aria-label='Basic example'
                                  aria-valuenow={ratingCount(3)}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div className='progress-bar' style={{ width: `${ratingCount(3)}%` }}></div>
                                </div>
                              </div>

                              <div className='star_given_count'>
                                <p>{pluginDetails.plugin?.ratings['3']}</p>
                              </div>
                            </div>
                            <div className='star_progress_row'>
                              <div className='star_count'>
                                <p>{t('plugin-details.2-star')}</p>
                              </div>
                              <div className='progress_bar'>
                                <div
                                  className='progress'
                                  role='progressbar'
                                  aria-label='Basic example'
                                  aria-valuenow={ratingCount(2)}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div className='progress-bar' style={{ width: `${ratingCount(2)}%` }}></div>
                                </div>
                              </div>

                              <div className='star_given_count'>
                                <p>{pluginDetails.plugin?.ratings['2']}</p>
                              </div>
                            </div>
                            <div className='star_progress_row'>
                              <div className='star_count'>
                                <p>{t('plugin-details.1-star')}</p>
                              </div>
                              <div className='progress_bar'>
                                <div
                                  className='progress'
                                  role='progressbar'
                                  aria-label='Basic example'
                                  aria-valuenow={ratingCount(1)}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div className='progress-bar' style={{ width: `${ratingCount(1)}%` }}></div>
                                </div>
                              </div>
                              <div className='star_given_count'>
                                <p>{pluginDetails.plugin?.ratings['1']}</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className='back_to_login'>
                    <a href='#'>{t('plugin-details.Log-in-to-submit-a-review.')}</a>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='container'>
            <div className='sticky-container'>
              <div className='selected-plugin'>
                <span className='text-white'>Selected Plugin Count: </span>
              </div>
              <div className='sticky-button'>
                <button className='orange_btn mx-1' type='button'>
                  Clear
                </button>
                <button className='orange_btn mx-1' type='button' >
                  Queue
                </button>
                <button className="orange_btn mx-1" type="button">Project</button>
              </div>
            </div>
          </div> */}
            </div>
          </main>
        )}
      </>
    </ErrorBoundary>
  );
};

export default PluginDetails;
