/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useProjectQuery } from './api';

const initialState = {
  items: [],
  data: null,
  status: null,
  isLoading: false,
  isError: false,
  errorMessage: null,
};

export const fetchQueueData: any = createAsyncThunk('projects/fetchQueueData', async (_, { rejectWithValue }) => {
  // You can use useProjectQuery here to fetch data
  // console.log("useProjectQuery",useProjectQuery({'with-plugins':true}));
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/getQueueDataByUser`,
      method: 'POST',
      data: {},
      headers: {
        // Accept: "multipart/form-data",
        // 'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

const queueSlice = createSlice({
  name: 'queuedata',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchQueueData.pending]: (state: any, action: any) => {
      state.status = 'pending';
      state.isLoading = true;
      state.isError = false; // Reset error state when starting a new request
      state.errorMessage = null; // Clear previous error message
    },
    [fetchQueueData.fulfilled]: (state: any, action: any) => {
      state.status = 'success';
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    [fetchQueueData.rejected]: (state: any, action: any) => {
      state.status = 'rejected';
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

export default queueSlice.reducer;
