import React from 'react';

interface TicketListProps {
  ticketData: any;
}

const TicketList: React.FC<TicketListProps> = ({ ticketData }: TicketListProps) => {
  const visitTicket = (item: any): void => {
    const newWindow = window.open(
      `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/support/?wpsc-section=ticket-list&ticket-id=${item.id}`,
      '_blank'
    );
    if (newWindow) {
      newWindow.focus();
    } else {
      // Popup blocked, handle the error or notify the user
      console.error('Popup blocked!');
    }
  };

  return (
    <>
      <div className='row align-items-center'>
        <div className='col'>
          <div className='page_title mt-4 mb-4 ms-3 secondary-color'>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='inlineRadioOptions'
                id='inlineRadio1'
                value='option1'
              />
              <label className='form-check-label' htmlFor='inlineRadio1'>
                All
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='inlineRadioOptions'
                id='inlineRadio2'
                value='option2'
              />
              <label className='form-check-label' htmlFor='inlineRadio2'>
                On Hold
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='inlineRadioOptions'
                id='inlineRadio3'
                value='option3'
              />
              <label className='form-check-label' htmlFor='inlineRadio3'>
                In progress
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='inlineRadioOptions'
                id='inlineRadio3'
                value='option3'
              />
              <label className='form-check-label' htmlFor='inlineRadio3'>
                Completed
              </label>
            </div>
          </div>
        </div>
        <div className='col-auto'>
          <div className='search'>
            <div className='search_main'>
              <input type='text' placeholder='Search here' />
              <svg
                aria-hidden='true'
                focusable='false'
                data-prefix='fas'
                data-icon='magnifying-glass'
                className='svg-inline--fa fa-magnifying-glass '
                role='img'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 512 512'
              >
                <path
                  fill='#6DB1BC'
                  d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z'
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Id</th>
              <th scope='col'>Email</th>
              <th scope='col'>Subject</th>
              <th scope='col'>Category</th>
              {/*  */}
              <th scope='col'>Last update date</th>
              <th scope='col'>Status</th>
              <th scope='col'>View Details</th>
            </tr>
          </thead>
          <tbody>
            {ticketData.map((ik: any) => {
              return (
                <tr>
                  <td>{ik.id}</td>
                  <td>{ik.email}</td>
                  <td>{ik.subject}</td>
                  <td>{ik.category.name}</td>

                  <td>{ik.date_created}</td>
                  <td>
                    <span
                      className='ticket_status'
                      style={{
                        backgroundColor: ik.status.bg_color,
                        color: ik.status.color,
                        width: 'auto',
                        padding: '5px 10px',
                        borderRadius: '10px',
                      }}
                    >
                      {ik.status.name}
                    </span>
                  </td>
                  <td>
                    <button type='button' className='cblue_btn' onClick={() => visitTicket(ik)}>
                      Visit Ticket
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TicketList;
